//-----------------------------------
//-- Bibliotecas a Importar
//-----------------------------------
import Vue from 'vue'

//-----------------------------------
//-- VUEX (Tratamiento del Store)
//-----------------------------------
import Vuex from 'vuex'
Vue.use(Vuex)


//-------------------------------------------------------------------------------------------
//-- STORE : Almacenamiento de Variables y evaluación de sus CAMBIOS (Mutaciones)
//-------------------------------------------------------------------------------------------


const igs_store = new Vuex.Store({

  //-------------------------------------------------
  //-- Estados (Variables)
  //-------------------------------------------------
  state : {

    strUsuarioAutenticado : true,
    strAbandonarSesion    : false,
    strMenuPrincipal      : true,

    strScreenWidth        : 0,
    strScreenHeight       : 0,

    strModuleName         : '',

    //..................................

    strDesdeFechaConsulta : '00000101',
    strHastaFechaConsulta : '99991231',

    strCalendarioListaImagenColor : {
      primary:   '#595cde',
      secondary: '#93a0bd',
      ternary:   '#fd8b0d',
      border:    '#e6e6e6',
      light:     '#ffffff',
      dark:      '#000000',
      hovers: {
        day:     '#fd8b0d',
        range:   '#e6e6e6'
      }
    },

    //..................................

    strUsuario: 'U000000',
    strDescripcionUsuario: 'Usuario Demostración',
    strMailUsuario: 'desconocido@dominio.com',

    //..................................

    strDistribuidor: 'D000000',
    strDescripcionDistribuidor: 'Empresa ABC',
    strDistribuidorListaImagenColor : ["#000000", "#595cde", "#fd8b0d", "#0369cf", "#0000ff"],
    strNifDistribuidorOfuscado: 'VVdwamQwNVVUWGhPVkZWNg==',
    strFormatoRepresentacionMapa : '1',

    //..................................

    strAlmacen: 'CENTRAL',
    strAlmacenDemarcacion: '01',

    //..................................

    strIDSesion: '000000000000123456cm',
    strListaUDA: [],

    strListaOperarios:  [],
    strListaArticulos:  [],

    strListaDistribuidores: [ 
      { cod:'D000000', des:'Empresa ABC', nif:'B70531553', css:'', lic:[]  }
    ],

    strListaDistribuidoresSeleccionables : [],
    strListaDistribuidoresSeleccionados  : [],

    //..................................

    strMenuBC  : [],

    //..................................

    strRecibiendoDatos: false,
    strIndicadorRefrescarVista : 0,
    strIndicadorPorcentajeActividad : 0,

    //..................................

    strUbicacionVaciaCodigo : '......',

    //..................................

    strNumeroArticulosCatalogoGeneral       : 0,

    //..................................

    strReferenciaPedido                     : '',
    strImportePedido                        : 0,

    //..................................

    strTPVMensajeTitulo                     : '',
    strTPVMensajeSubtitulo                  : '',
    strTPVMensajeImporte                    : '',

    strTPVMerchantPayMethods                : '',
    strTPVMerchantIdentifier                : '',
    strTPVLeyendaDatosTarjeta               : '',

    //..................................

    //-- Info TPVs
    strT001M030          : [],
    strT001M030_Indice   : 0,

  },


  //-------------------------------------------------
  //-- Mutaciones (Cambios sobre Variables)
  //-------------------------------------------------
   mutations : {

      p10StrUsuarioAutenticado(state,valor)             {   state.strUsuarioAutenticado       = valor;    },
      p10StrAbandonarSesion(state,valor)                {   state.strAbandonarSesion          = valor;    },
      p10StrMenuPrincipal(state,valor)                  {   state.strMenuPrincipal            = valor;    },

      p10StrScreenWidth(state,valor)                    {   state.strScreenWidth              = valor;    },
      p10StrScreenHeight(state,valor)                   {   state.strScreenHeight             = valor;    },

      p10StrModuleName(state,valor)                     {   state.strModuleName               = valor;    },

      //..................................

      p10StrDesdeFechaConsulta(state,valor)             {   state.strDesdeFechaConsulta             = valor;    },
      p10StrHastaFechaConsulta(state,valor)             {   state.strHastaFechaConsulta             = valor;    },
      p10StrCalendarioListaImagenColor(state,valor)     {   state.strCalendarioListaImagenColor     = valor;    },

      //..................................

      p10StrUsuario(state,valor)                        {   state.strUsuario                        = valor;    },
      p10StrDescripcionUsuario(state,valor)             {   state.strDescripcionUsuario             = valor;    },
      p10StrMailUsuario(state,valor)                    {   state.strMailUsuario                    = valor;    },

      //..................................
    
      p10StrDistribuidor(state,valor)                   {   
        state.strDistribuidor                   = valor;     

        //-- Establecer Almacén
        state.strAlmacen = 'CENTRAL';
        if (valor == "D050001") { state.strAlmacen = 'ALMACEN'; }
        if (valor == "D100001") { state.strAlmacen = 'MATESA';  }
      },
      p10StrDescripcionDistribuidor(state,valor)        {   state.strDescripcionDistribuidor        = valor;     },
      p10StrDistribuidorListaImagenColor(state,valor)   {   state.strDistribuidorListaImagenColor   = valor;     },
      p10StrNifDistribuidorOfuscado(state,valor)        {   state.strNifDistribuidorOfuscado        = valor;     },
      p10StrFormatoRepresentacionMapa(state,valor)      {   state.strFormatoRepresentacionMapa      = valor;     },

      //..................................
    
      p10StrAlmacen(state,valor)                        {   state.strAlmacen                        = valor;     },
      p10StrAlmacenDemarcacion(state,valor)             {   state.strAlmacenDemarcacion             = valor;     },

      //..................................

      p10StrIDSesion(state,valor)                       {   state.strIDSesion                       = valor;     },
      
      //=========================================================
      //== Cambio de Lista de DISTRIBUIDORES - ALMACENES
      //=========================================================
      p10StrListaUDA(state,valor)                   { 
        //console.log('-- ListaUDA --');
        //console.dir(valor);  

        //-- Actualizar Lista Completa de Distribuidores y Almacenes
        state.strListaUDA                 = valor;    

        //...................................................
        //-- Actualizar Lista de Distribuidores ÚNICOS
        //...................................................
        const resultado1 = [];
        const resultado2 = [];

        const mapa      = new Map();

        for (const item of valor) {
          if(!mapa.has(item.d1)){
            mapa.set(item.d1, true);      //-- Actualizar Mapa

            resultado1.push({            //-- Agregar Nuevo Elemento a la Lista
               cod: item.d1,
               des: item.d2,
               nif: item.d3,
               frm: item.d5,
               css: item.dcss,
               lic: item.dlic
            });

            resultado2.push({            //-- Agregar Nuevo Elemento a la Lista
              value: item.d1,
              label: item.d2
            });
          }
        }

        //............................................................
        //-- Actualizar la Nueva Lista de Distribuidores en el STORE
        //............................................................
        var resultado2Sorted = resultado2.sort(function(a, b){
                                                  var nameA=a.value.toLowerCase(), nameB=b.value.toLowerCase();
                                                  if (nameA < nameB) { return -1; }
                                                  if (nameA > nameB) { return  1; }
                                                  return 0; 
                                              });

        //-- Todos los Distribuidores (formato IH1)
        state.strListaDistribuidores               = resultado1.sort();

        //-- Todos los Distribuidores (formato VueSelectSides)
        state.strListaDistribuidoresSeleccionables = resultado2Sorted;

        //-- Lista de Distribuidores Seleccionados
        state.strListaDistribuidoresSeleccionados  = [];

        //.. Evaluar si se ha recibido, al menos, UN distribuidor
        if (resultado2Sorted.length > 0) {
          //-- Inicializar Lista de Distribuidores Seleccionados con el PRIMER elemento seleccionable
          state.strListaDistribuidoresSeleccionados.push(resultado2Sorted[0].value);
        }
      
        //console.log('-- Lista Distribuidores Seleccionados --');
        //console.dir(state.strListaDistribuidoresSeleccionados);
      },

      p10StrListaOperarios(state,valor)                      {   state.strListaOperarios                      = valor;    },
      p10StrListaArticulos(state,valor)                      {   state.strListaArticulos                      = valor;    },

      p10StrListaDistribuidores(state,valor)                 {   state.strListaDistribuidores                 = valor;    },

      p10StrListaDistribuidoresSeleccionables(state,valor)   {   state.strListaDistribuidoresSeleccionables   = valor;    },
      p10StrListaDistribuidoresSeleccionados(state,valor)    {   state.strListaDistribuidoresSeleccionados    = valor;    },

      //..................................
      
      p10StrMenuBC(state,valor)                              {   state.strMenuBC    = valor;                              },

      //..................................

      p10StrRecibiendoDatos(state,valor)                     {   state.strRecibiendoDatos                     = valor;    },
      p10StrIndicadorRefrescarVista(state,valor)             {   
        //console.log('p10StrIndicadorRefrescarVista');
        state.strIndicadorRefrescarVista  += valor;    
      },
      p10StrIndicadorPorcentajeActividad(state,valor)        {   state.strIndicadorPorcentajeActividad        = valor;    },

      //..................................

      p10StrUbicacionVaciaCodigo(state,valor)                {   state.strUbicacionVaciaCodigo                = valor;    },

      //..................................

      p10StrNumeroArticulosCatalogoGeneral(state,valor)      {   state.strNumeroArticulosCatalogoGeneral      = valor;    },
      
      //..................................

      p10StrReferenciaPedido(state,valor)                   {   state.strReferenciaPedido                     = valor;    },
      p10StrImportePedido(state,valor)                      {   state.strImportePedido                        = valor;    },

      //..................................

      p10StrTPVMensajeTitulo(state,valor)                   {   state.strTPVMensajeTitulo                     = valor;    },
      p10StrTPVMensajeSubtitulo(state,valor)                {   state.strTPVMensajeSubtitulo                  = valor;    },
      p10StrTPVMensajeImporte(state,valor)                  {   state.strTPVMensajeImporte                    = valor;    },

      p10StrTPVMerchantPayMethods(state,valor)              {   state.strTPVMerchantPayMethods                = valor;    },
      p10StrTPVMerchantIdentifier(state,valor)              {   state.strTPVMerchantIdentifier                = valor;    },
      p10StrTPVLeyendaDatosTarjeta(state,valor)             {   state.strTPVLeyendaDatosTarjeta               = valor;    },

      //..................................

      p10StrT001M030(state, valor)                          {   state.strT001M030                             = valor;   },
      p10StrT001M030_Indice(state, valor)                   {   state.strT001M030_Indice                      = valor;   },
      

  },

  //-------------------------------------------------
  //-- Getters (Obtener Valor de Variables,
  //--          o resultados filtrados)
  //-------------------------------------------------
  getters : {

      strUsuarioAutenticado                   : state => state.strUsuarioAutenticado,
      strAbandonarSesion                      : state => state.strAbandonarSesion,
      strMenuPrincipal                        : state => state.strMenuPrincipal,

      strScreenWidth                          : state => state.strScreenWidth,
      strScreenHeight                         : state => state.strScreenHeight,

      strModuleName                           : state => state.strModuleName,

      //..................................

      strDesdeFechaConsulta                   : state => state.strDesdeFechaConsulta,
      strHastaFechaConsulta                   : state => state.strHastaFechaConsulta,
      strCalendarioListaImagenColor           : state => state.strCalendarioListaImagenColor,

      //..................................

      strUsuario                              : state => state.strUsuario,
      strDescripcionUsuario                   : state => state.strDescripcionUsuario,
      strMailUsuario                          : state => state.strMailUsuario,

      //..................................

      strDistribuidor                         : state => state.strDistribuidor,
      strDescripcionDistribuidor              : state => state.strDescripcionDistribuidor,
      strDistribuidorListaImagenColor         : state => state.strDistribuidorListaImagenColor,
      strNifDistribuidorOfuscado              : state => state.strNifDistribuidorOfuscado,
      strFormatoRepresentacionMapa            : state => state.strFormatoRepresentacionMapa,

      //..................................

      strAlmacen                              : state => state.strAlmacen,
      strAlmacenDemarcacion                   : state => state.strAlmacenDemarcacion,

      //..................................

      strIDSesion                             : state => state.strIDSesion,
      strListaUDA                             : state => state.strListaUDA,

      strListaOperarios                       : state => state.strListaOperarios,
      strListaArticulos                       : state => state.strListaArticulos,

      strListaDistribuidores                  : state => state.strListaDistribuidores,

      strListaDistribuidoresSeleccionables    : state => state.strListaDistribuidoresSeleccionables,
      strListaDistribuidoresSeleccionados     : state => state.strListaDistribuidoresSeleccionados,

      //..................................

      strMenuBC                               : state => state.strMenuBC,

      //..................................

      strRecibiendoDatos                      : state => state.strRecibiendodatos,
      strIndicadorRefrescarVista              : state => state.strIndicadorRefrescarVista,
      strIndicadorPorcentajeActividad         : state => state.strIndicadorPorcentajeActividad,

      //..................................

      strUbicacionVaciaCodigo                 : state => state.strUbicacionVaciaCodigo,

      //..................................

      strNumeroArticulosCatalogoGeneral       : state => state.strNumeroArticulosCatalogoGeneral,

      //..................................

      strReferenciaPedido                     : state => state.strReferenciaPedido,
      strImportePedido                        : state => state.strImportePedido,

      //..................................

      strTPVMensajeTitulo                     : state => state.strTPVMensajeTitulo,
      strTPVMensajeSubtitulo                  : state => state.strTPVMensajeSubtitulo,
      strTPVMensajeImporte                    : state => state.strTPVMensajeImporte,

      strTPVMerchantPayMethods                : state => state.strTPVMerchantPayMethods,
      strTPVMerchantIdentifier                : state => state.strTPVMerchantIdentifier,
      strTPVLeyendaDatosTarjeta               : state => state.strTPVLeyendaDatosTarjeta,

      //........................................

      strT001M030                             : state => state.strT001M030,
      strT001M030_Indice                      : state => state.strT001M030_Indice,
      
  },

  //-------------------------------------------------------------
  //-- Actions (Métodos para alterar el valor de las Variables)
  //-------------------------------------------------------------
  actions : {

    //-- Ejemplo de Acciones : Llamada a sucesivos (commit) en el interior del (Store)
    /*
    p20SiguienteMensaje(context) {      
      if (context.state.strHeadMsgTexto.length > 0) {
        context.commit('p10StrHeadMsgTexto', '');
        context.commit('p10StrIndicadorRefrescarVista', 1);
      }      
    }
    */

  }

 
});

//Vue.prototype.$igs_store = igsStore
//module.exports = igsStore;

export default igs_store 

