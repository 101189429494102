//---------------------------------------------------
//-- Mixins (Rutinas y Código de Acceso Global)
//---------------------------------------------------

import { SnotifyPosition, SnotifyStyle }     from 'vue-snotify';
import * as fflate                           from 'fflate';


//-- Comunicación HTTP, FTP, ...
const axios = require("axios");

export default {


  //--------------------- Variables GLOBALES  ---------------------------
  data () {
      return {

        HtmlEntitiesMap : {
          "'": "&apos;",
          "<": "&lt;",
          ">": "&gt;",
          " ": "&nbsp;",
          "¡": "&iexcl;",
          "¢": "&cent;",
          "£": "&pound;",
          "¤": "&curren;",
          "¥": "&yen;",
          "¦": "&brvbar;",
          "§": "&sect;",
          "¨": "&uml;",
          "©": "&copy;",
          "ª": "&ordf;",
          "«": "&laquo;",
          "¬": "&not;",
          "®": "&reg;",
          "¯": "&macr;",
          "°": "&deg;",
          "±": "&plusmn;",
          "²": "&sup2;",
          "³": "&sup3;",
          "´": "&acute;",
          "µ": "&micro;",
          "¶": "&para;",
          "·": "&middot;",
          "¸": "&cedil;",
          "¹": "&sup1;",
          "º": "&ordm;",
          "»": "&raquo;",
          "¼": "&frac14;",
          "½": "&frac12;",
          "¾": "&frac34;",
          "¿": "&iquest;",
          "À": "&Agrave;",
          "Á": "&Aacute;",
          "Â": "&Acirc;",
          "Ã": "&Atilde;",
          "Ä": "&Auml;",
          "Å": "&Aring;",
          "Æ": "&AElig;",
          "Ç": "&Ccedil;",
          "È": "&Egrave;",
          "É": "&Eacute;",
          "Ê": "&Ecirc;",
          "Ë": "&Euml;",
          "Ì": "&Igrave;",
          "Í": "&Iacute;",
          "Î": "&Icirc;",
          "Ï": "&Iuml;",
          "Ð": "&ETH;",
          "Ñ": "&Ntilde;",
          "Ò": "&Ograve;",
          "Ó": "&Oacute;",
          "Ô": "&Ocirc;",
          "Õ": "&Otilde;",
          "Ö": "&Ouml;",
          "×": "&times;",
          "Ø": "&Oslash;",
          "Ù": "&Ugrave;",
          "Ú": "&Uacute;",
          "Û": "&Ucirc;",
          "Ü": "&Uuml;",
          "Ý": "&Yacute;",
          "Þ": "&THORN;",
          "ß": "&szlig;",
          "à": "&agrave;",
          "á": "&aacute;",
          "â": "&acirc;",
          "ã": "&atilde;",
          "ä": "&auml;",
          "å": "&aring;",
          "æ": "&aelig;",
          "ç": "&ccedil;",
          "è": "&egrave;",
          "é": "&eacute;",
          "ê": "&ecirc;",
          "ë": "&euml;",
          "ì": "&igrave;",
          "í": "&iacute;",
          "î": "&icirc;",
          "ï": "&iuml;",
          "ð": "&eth;",
          "ñ": "&ntilde;",
          "ò": "&ograve;",
          "ó": "&oacute;",
          "ô": "&ocirc;",
          "õ": "&otilde;",
          "ö": "&ouml;",
          "÷": "&divide;",
          "ø": "&oslash;",
          "ù": "&ugrave;",
          "ú": "&uacute;",
          "û": "&ucirc;",
          "ü": "&uuml;",
          "ý": "&yacute;",
          "þ": "&thorn;",
          "ÿ": "&yuml;",
          "Œ": "&OElig;",
          "œ": "&oelig;",
          "Š": "&Scaron;",
          "š": "&scaron;",
          "Ÿ": "&Yuml;",
          "ƒ": "&fnof;",
          "ˆ": "&circ;",
          "˜": "&tilde;",
          "Α": "&Alpha;",
          "Β": "&Beta;",
          "Γ": "&Gamma;",
          "Δ": "&Delta;",
          "Ε": "&Epsilon;",
          "Ζ": "&Zeta;",
          "Η": "&Eta;",
          "Θ": "&Theta;",
          "Ι": "&Iota;",
          "Κ": "&Kappa;",
          "Λ": "&Lambda;",
          "Μ": "&Mu;",
          "Ν": "&Nu;",
          "Ξ": "&Xi;",
          "Ο": "&Omicron;",
          "Π": "&Pi;",
          "Ρ": "&Rho;",
          "Σ": "&Sigma;",
          "Τ": "&Tau;",
          "Υ": "&Upsilon;",
          "Φ": "&Phi;",
          "Χ": "&Chi;",
          "Ψ": "&Psi;",
          "Ω": "&Omega;",
          "α": "&alpha;",
          "β": "&beta;",
          "γ": "&gamma;",
          "δ": "&delta;",
          "ε": "&epsilon;",
          "ζ": "&zeta;",
          "η": "&eta;",
          "θ": "&theta;",
          "ι": "&iota;",
          "κ": "&kappa;",
          "λ": "&lambda;",
          "μ": "&mu;",
          "ν": "&nu;",
          "ξ": "&xi;",
          "ο": "&omicron;",
          "π": "&pi;",
          "ρ": "&rho;",
          "ς": "&sigmaf;",
          "σ": "&sigma;",
          "τ": "&tau;",
          "υ": "&upsilon;",
          "φ": "&phi;",
          "χ": "&chi;",
          "ψ": "&psi;",
          "ω": "&omega;",
          "ϑ": "&thetasym;",
          "ϒ": "&Upsih;",
          "ϖ": "&piv;",
          "–": "&ndash;",
          "—": "&mdash;",
          "‘": "&lsquo;",
          "’": "&rsquo;",
          "‚": "&sbquo;",
          "“": "&ldquo;",
          "”": "&rdquo;",
          "„": "&bdquo;",
          "†": "&dagger;",
          "‡": "&Dagger;",
          "•": "&bull;",
          "…": "&hellip;",
          "‰": "&permil;",
          "′": "&prime;",
          "″": "&Prime;",
          "‹": "&lsaquo;",
          "›": "&rsaquo;",
          "‾": "&oline;",
          "⁄": "&frasl;",
          "€": "&euro;",
          "ℑ": "&image;",
          "℘": "&weierp;",
          "ℜ": "&real;",
          "™": "&trade;",
          "ℵ": "&alefsym;",
          "←": "&larr;",
          "↑": "&uarr;",
          "→": "&rarr;",
          "↓": "&darr;",
          "↔": "&harr;",
          "↵": "&crarr;",
          "⇐": "&lArr;",
          "⇑": "&UArr;",
          "⇒": "&rArr;",
          "⇓": "&dArr;",
          "⇔": "&hArr;",
          "∀": "&forall;",
          "∂": "&part;",
          "∃": "&exist;",
          "∅": "&empty;",
          "∇": "&nabla;",
          "∈": "&isin;",
          "∉": "&notin;",
          "∋": "&ni;",
          "∏": "&prod;",
          "∑": "&sum;",
          "−": "&minus;",
          "∗": "&lowast;",
          "√": "&radic;",
          "∝": "&prop;",
          "∞": "&infin;",
          "∠": "&ang;",
          "∧": "&and;",
          "∨": "&or;",
          "∩": "&cap;",
          "∪": "&cup;",
          "∫": "&int;",
          "∴": "&there4;",
          "∼": "&sim;",
          "≅": "&cong;",
          "≈": "&asymp;",
          "≠": "&ne;",
          "≡": "&equiv;",
          "≤": "&le;",
          "≥": "&ge;",
          "⊂": "&sub;",
          "⊃": "&sup;",
          "⊄": "&nsub;",
          "⊆": "&sube;",
          "⊇": "&supe;",
          "⊕": "&oplus;",
          "⊗": "&otimes;",
          "⊥": "&perp;",
          "⋅": "&sdot;",
          "⌈": "&lceil;",
          "⌉": "&rceil;",
          "⌊": "&lfloor;",
          "⌋": "&rfloor;",
          "⟨": "&lang;",
          "⟩": "&rang;",
          "◊": "&loz;",
          "♠": "&spades;",
          "♣": "&clubs;",
          "♥": "&hearts;",
          "♦": "&diams;"
      }

     }          
        
    },



  //----------------- Variables Calculadas GLOBALES  --------------------
  computed: {

    //-- Retornar el Valor del ANCHO de Pantala
    f99AppScreenWidth() {
      //console.log('-- f99AppScreenWidth --'+this.$appScreenWidth);
      return this.$appScreenWidth;
    },

    //-- Retornar el Valor del ALTO de Pantalla
    f99AppScreenHeight() {
      //console.log('-- f99AppScreenHeight --'+this.$appScreenHeight);
      return this.$appScreenHeight;
    }

  },



  //--------------------- Observadores GLOBALES -------------------------
  watch: {
  },



  //----------------------- Métodos GLOBALES  ---------------------------
  methods: {

      //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      //:::  fNNxxxxxxx  - Modelo de Nomenclatura a aplicar
      //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      //:::
      //:::      (f) = Función        (SÍ retorna un resultado)
      //:::      (p) = Procedimiento  (NO retorna resultados)
      //:::
      //:::      (NN) = Tipo de Función/Procedimiento
      //:::       60  - Procesos de caracter GENERAL
      //:::       70  - Tratamiento de FECHAS
      //:::       90  - Mostrar Contenido en Pantalla
      //:::       97  - Lectura/Escritura Datos sobre URL
      //:::       99  - Mensajes Interactivos (Alertas, Preguntas, ...)
      //:::
      //:::      (xxxxxx) = Nombre de Función/Procedimiento
      //:::
      //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

      
      //----------------------------------------------------------
      //--- Rutinas Generales al Inicio de cada Vista
      //----------------------------------------------------------
      p10Inicio() {

        //-- Si no hay sesión : Página Inicial
        if ( this.$store.state.strIDSesion == "") {
            this.p99AnuncioIA1('4','7','Atención', 'Sesión CADUCADA<br/>Debe identificarse de nuevo',10);
            this.$router.push('/');
        }

        //-- TODO : Si la sesión no es de 20 caracteres, o los 2 últimos no son "cm" : Sesión Corrupta
  

      },

      //----------------------------------------------------------
      //--- Conversión AAAAMMDD en DD-MM-AAAA
      //----------------------------------------------------------
      f70AMD2fDMA(fecha){
        var ano       = fecha.substr(0,4);
        var mes       = fecha.substr(4,2);
        var dia       = fecha.substr(6,2);
  
        return dia + global.lnkDateSeparator + mes + global.lnkDateSeparator + ano;
      },

      //----------------------------------------------------------
      //--- Conversión AAAAMMDD en DD-MM-AA
      //----------------------------------------------------------
      f70AMD2sDMA(fecha){
        var ano       = fecha.substr(2,2);
        var mes       = fecha.substr(4,2);
        var dia       = fecha.substr(6,2);
  
        return dia + global.lnkDateSeparator + mes + global.lnkDateSeparator + ano;
      },

      //----------------------------------------------------------
      //--- Conversión HHMMSS en HH:MM:SS
      //----------------------------------------------------------
      f70HMS2fHMS(tiempo){
        var hora      = tiempo.substr(0,2);
        var minuto    = tiempo.substr(2,2);
        var segundo   = tiempo.substr(4,2);
  
        //console.log('Tiempo : ['+tiempo+']');
        //console.log(' Hora  : ['+hora+'] Minuto : ['+minuto+'] Segundo : ['+segundo+']');
        return hora + global.lnkTimeSeparator + minuto + global.lnkTimeSeparator + segundo;
      },

      //----------------------------------------------------------
      //--- Conversión (Segundos) en HH:MM:SS
      //----------------------------------------------------------
      f70Segundos2fHMS(segundos){
        const date = new Date(null);
        if (isNaN(segundos))       { segundos=0; }
        if (!isFinite(segundos))   { segundos=0; }

        date.setSeconds(segundos);
        //console.log(' segundos : '+segundos);
        //console.log(' dateString : ('+date.toISOString()+')');
        //console.log(' longitud : '+date.toISOString().length);
        return date.toISOString().slice(11, 19);
      },

      //------------------------------------------------------------------
      //--- Creación de HASH SHA-256, a partir del (mensaje) indicado
      //--- Formatos de Salida : (1)-Hexadecimal  (2)-Base64
      //------------------------------------------------------------------
      f70SHA256(mensaje,formato) {
        var crypto = require('crypto');

        if (formato == '1') {
          const hash = crypto.createHash('sha256').update(mensaje).digest('hex');
          return hash;
        } else {
          const hash = crypto.createHash('sha256').update(mensaje).digest('base64');
          return hash;
        }
                
      },

      //----------------------------------------------------------
      //--- Ofuscar Texto (Encode)
      //----------------------------------------------------------
      f75OfuscarTexto(texto) {
        var base64 = require('base-64');

        //-- Fase I
        var resultado = base64.encode(texto);

        //-- Fase II
        resultado = base64.encode(resultado);

        //-- Fase III
        resultado = base64.encode(resultado);

        //-- Devolver texto Ofuscado
        return resultado;
      },


      //---------------------------------------------------------------------------
      //--- Esta función comprime el TEXTO indicado, por medio de :
      //--    a) Comprimir con GZIP  (reducir tamaño)
      //--    b) Codificar en Base64 (para facilitar un transporte HTTP seguro)
      //---------------------------------------------------------------------------
      f75GzipB64Texto(texto) {

        //-- Convertir a UniCode
        const buf = fflate.strToU8(texto);

        //-- Comprimir (GZIP)
        const compressedString = fflate.strFromU8(
          fflate.compressSync(buf),
          true
        );            

        //-- Codificar en Base64
        var base64 = require('base-64');
        var encodedB64 = base64.encode(compressedString);

        //-- Retornar el valor calculado
        return encodedB64;

      },

      //----------------------------------------------------------
      //--- Desofuscar Texto (Decode)
      //----------------------------------------------------------
      f75DesofuscarTexto(texto) {
        var base64 = require('base-64');

        //-- Fase I
        var resultado = base64.decode(texto);

        //-- Fase II
        resultado = base64.decode(resultado);

        //-- Fase III
        resultado = base64.decode(resultado);

        //-- Devolver texto Ofuscado
        return resultado;
      },

      //---------------------------------------------------------------------------
      //--- Esta función descomprime el TEXTO indicado, por medio de :
      //--    a) Decodificar en Base64 (tras recibir el texto via HTTP)
      //--    b) Descomprimir con GZIP (obtener el "texto original")
      //---------------------------------------------------------------------------
      f75GUnzipB64Texto(textoComprimido) {

        //-- Decodificar Base64 --> Texto Binario
        var base64 = require('base-64');
        var decodedB64 = base64.decode(textoComprimido);

        //-- Descomprimir
        const decompressed = fflate.decompressSync(
          fflate.strToU8(decodedB64, true)
        );

        //-- Convertir de Unicode a Texto
        const textoOriginal = fflate.strFromU8(decompressed);

        //-- Retornar el valor obtenido
        return textoOriginal;
      },

      //----------------------------------------------------------
      //--- Decodificar HTML Entities
      //----------------------------------------------------------
      f79DecodeHTML(texto) {

        //-- Casos especiales
        if ((typeof texto == 'undefined') || (texto == '')) {
          return '';
        }

        //-- Como norma general
        var entityMap = this.HtmlEntitiesMap;
        for (var key in entityMap) {
            var entity = entityMap[key];
            var regex = new RegExp(entity, 'g');
            texto = texto.replace(regex, key);
        }

        texto = texto.replace(/&quot;/g, '"');
        texto = texto.replace(/&amp;/g, '&');

        return texto;
      },
      
      //----------------------------------------------------------
      //--- Codificar HTML Entities
      //----------------------------------------------------------
      f79EncodeHTML(texto) {
        //-- Casos especiales
        if ((typeof texto == 'undefined') || (texto == '')) {
          return '';
        }
  
        //-- Como norma general
        var entityMap = this.HtmlEntitiesMap;
        texto = texto.replace(/&/g, '&amp;');
        texto = texto.replace(/"/g, '&quot;');

        for (var key in entityMap) {
            var entity = entityMap[key];
            var regex = new RegExp(key, 'g');
            texto = texto.replace(regex, entity);
        }

        return texto;
      },    

      //--
      colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
        var color1 = rgbColor1;
        var color2 = rgbColor2;
        var fade = fadeFraction;
      
        // Do we have 3 colors for the gradient? Need to adjust the params.
        if (rgbColor3) {
          fade = fade * 2;
      
          // Find which interval to use and adjust the fade percentage
          if (fade >= 1) {
            fade -= 1;
            color1 = rgbColor2;
            color2 = rgbColor3;
          }
        }
      
        var diffRed = color2.red - color1.red;
        var diffGreen = color2.green - color1.green;
        var diffBlue = color2.blue - color1.blue;
      
        var gradient = {
          red: parseInt(Math.floor(color1.red + (diffRed * fade)), 10),
          green: parseInt(Math.floor(color1.green + (diffGreen * fade)), 10),
          blue: parseInt(Math.floor(color1.blue + (diffBlue * fade)), 10),
        };
      
        return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
      },      

      //-------------------------------------------------------------------------
      //--- Retornar el RGB a aplicar (según el Porcentaje de Stock)
      //-------------------------------------------------------------------------
      f80ColorGradienteStock(porcentaje,filtroGradiente) {
        //-- Color de Último Recurso (Blanco)
        let color = global.lnkDefaultMPAColors[1];

        /*
        //-- Casos Especiales
        if          ((porcentaje <  0) &&  ((filtroGradiente == -1) || (filtroGradiente == 0))) {
            color = global.lnkDefaultGSN;
        } else if   ((porcentaje == 0) &&  ((filtroGradiente == -1) || (filtroGradiente == 1))) {
            color = global.lnkDefaultGSZ;
          } else if ((porcentaje > 100) && ((filtroGradiente == -1) || (filtroGradiente == 6))) {
            color = global.lnkDefaultGSP;
        } else {
          -- Método 1 -- (Array)
          let indice = Math.round(porcentaje / 100 * 31);
          color = global.lnkDefaultGOH[indice];
          */

          /*
          -- Método 2 -- (Fórmula)
          let lowColor      = { red: 217, green: 83,  blue: 79 };
          let mediumColor   = { red: 240, green: 173, blue: 78 };
          let highColor     = { red: 92,  green: 184, blue: 91 };  
          let fadeFraction  = porcentaje / 100; 
          
          color = this.colorGradient(fadeFraction, lowColor, mediumColor, highColor);
        }
        */

        /*
        -- Método 3 -- (3 Valores)
        */
        if        ((porcentaje > 100)    && ((filtroGradiente == -1) || (filtroGradiente == 6))) {
          color = global.lnkDefaultMPAColors[6];
        } else if ((porcentaje == 100)   && ((filtroGradiente == -1) || (filtroGradiente == 5))) {
          color = global.lnkDefaultMPAColors[5];
        } else if  ((porcentaje > 66.66) && ((filtroGradiente == -1) || (filtroGradiente == 4))) {
          color = global.lnkDefaultMPAColors[4];
        } else if ((porcentaje > 33.33)  && ((filtroGradiente == -1) || (filtroGradiente == 3))) {
          color = global.lnkDefaultMPAColors[3];
        } else if ((porcentaje > 0)      && ((filtroGradiente == -1) || (filtroGradiente == 2))) {
          color = global.lnkDefaultMPAColors[2];
        } else if ((porcentaje == 0)     && ((filtroGradiente == -1) || (filtroGradiente == 1))) {
          color = global.lnkDefaultMPAColors[1];
        }

        //-- Retornar el resultado obtenido
        return color;
      },

      //-------------------------------------------------------------------------
      //--- Determinar "Color Texto" BLANCO / NEGRO según en "Color de Fondo"
      //-------------------------------------------------------------------------
      p80TextColorFromBackgroundColor(bgColor, lightColor, darkColor) {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.687) + (b * 0.114)) > 186) ? darkColor : lightColor;
      },

      //---------------------------------------------------------------
      //--- Complementar [numero_espacios] por la izquierda y derecha
      //---------------------------------------------------------------
      f91Pad(texto, numero_espacios) {
      
        //-- Verificar que se trata de un campo en formato "texto" (si no fuese así -> corregir)
        if (typeof texto == 'undefined') { texto = ""; }
        if (typeof texto == 'number')    { texto = texto.toString(); }

        //-- Dado que [numero_espacios] es opcional : revisar, y si viene sin cubrir -> asumir 0
        numero_espacios = numero_espacios || 0;

        //-- La concatenación pudiera contener algún fragmento "undefined"
        var n = texto.search("undefined");
        if (n !== -1) { texto = '...'; }

        //-- Convertir valor HTTP (%xy) en (caracter standard) (ejemplo : %3d -> '=')
        texto = unescape(texto);

        //-- Complementar con espacios
        return " ".repeat(numero_espacios) + texto + " ".repeat(numero_espacios);
      },


      //---------------------------------------------------------------
      //--- Complementar [numero_espacios] por la izquierda y derecha
      //---   (a) Valor a formatear (string/number)
      //---   (b) Longitud Final (VxD = 2)
      //---   (c) Carácter con el cual complementar (VxD = "0")
      //---------------------------------------------------------------
      f91PadLeft(a, b, c) { 
        return (new Array(b || 2).join(c || 0) + a).slice(-b)
      },

      //----------------------------------------------------------
      //--- Máscara ENTERA
      //----------------------------------------------------------
      f91Entero(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value)) { return ""; }

        //-- Determinar si precisa decimales (o no)
        let val  = Math.round(value);

        //-- Maquetar
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },


      //----------------------------------------------------------
      //--- Cantidad con Formato
      //----------------------------------------------------------
      f91Cantidad(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value)) { return ""; }

        //-- Determinar si precisa decimales (o no)
        let val  = Math.round(value);
        if (val != value) {  val = (value/1).toFixed(2).replace('.', ','); }

        //-- Maquetar
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      //----------------------------------------------------------
      //--- Importe con Formato
      //----------------------------------------------------------
      f91Importe(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value))       { return "";  }
        if (!isFinite(value))   { return "-"; }

        //-- Resto de Casos
        let val = (value/1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      //----------------------------------------------------------
      //--- Porcentaje con Formato
      //----------------------------------------------------------
      f91Porcentaje(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value))       { return "";  }
        if (!isFinite(value))   { return "-"; }

        //-- Resto de Casos
        let val = (value/1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      
      //--------------------------------------------------------------
      //--- Símbolo de la Moneda en la que está expresado el Importe
      //--------------------------------------------------------------
      f91Moneda(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value)) { return ""; }

        //-- Resto de Casos
        return " €";
      },

      //--------------------------------------------------------------
      //--- Convertir campo Fecha en Formato (aaaammdd) a Date
      //--------------------------------------------------------------
      f92AMD2Date(fecha) {
        //console.log('f92AMD2Date = '+fecha);
        var date = new Date(parseInt(fecha.substr(0,4)), parseInt(fecha.substr(4,2))-1 , parseInt(fecha.substr(6,2)) );
        //console.log('f92AMD2Date : '+date);
        return   date;
      },

      //--------------------------------------------------------------
      //--- Convertir campo Fecha en Formato (aaaammdd) a Date
      //--------------------------------------------------------------
      f92A_M_D2Date(fecha) {
        //console.log('f92A_M_D2Date = '+fecha);
        var date = new Date(parseInt(fecha.substr(0,4)), parseInt(fecha.substr(5,2))-1 , parseInt(fecha.substr(8,2)) );
        //console.log('f92A_M_D2Date : '+date);
        return   date;
      },

      
      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Fecha en Formato (aaaammdd)
      //--------------------------------------------------------------
      f92Date2AMD(date) {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();

        return   y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d;
      },

      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Fecha en Formato (aaaa-mm-dd)
      //--------------------------------------------------------------
      f92Date2A_M_D(date) {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();

        return   y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
      },


      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Fecha en Formato (dd-mm-aaaa)
      //--------------------------------------------------------------
      f92Date2DMA(date) {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        return (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y;
      },

      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Hora en Formato (HH:MM:SS)
      //--------------------------------------------------------------
      f92Date2HMS(date) {
        const h = date.getHours();
        const m = date.getMinutes();
        const s = date.getSeconds();
        return (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s;
      },

      //--------------------------------------------------------------
      //--- Convertir campo (Date) a (DTZ)
      //--------------------------------------------------------------
      f92Date2DTZ(date) {
        /*
        //-- Convertir a UTC
        var valor_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 
                                 date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

        //-- Convertir a tipo (Date)                                 
        var date_utc = new Date(valor_utc);
                                          
        //-- Descomponer                                 
        const d   = date_utc.getDate();
        const m   = date_utc.getMonth() + 1;
        const y   = date_utc.getFullYear();

        const hor = date_utc.getHours();
        const min = date_utc.getMinutes();
        const seg = date_utc.getSeconds();

        //-- Retornar valor completo
        return y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d + 'T' + (hor < 10 ? '0' : '') + hor + ':' + (min < 10 ? '0' : '') + min + ':' + (seg < 10 ? '0' : '') + seg + 'Z';
        */

        return date.toISOString();
      },

      //...........................................................
      //-- Descripción del Estado del Tipo de Movimiento
      //...........................................................
      f93DescripcionTipoMovimientoDMA(clave, situacion,parentesis) {

        //-- Inicializar Descripción del Tipo de Movimiento
        let texto1 = '';

        //-- Reemplazar CLAVE por TEXTO DESCRIPTIVO
        switch (clave) {
          case '10':
            texto1 = 'Entrada';
            break;

          case '18':
            texto1 = 'Ubic.Retorno';
            break;
  
          case '19':
            texto1 = 'Ubic.Entrada';
            break;
  
          case '20':
            texto1 = 'Carga';
            break;

          case '21':
            texto1 = 'Retorno';
            break;

          case '2C':
            texto1 = 'Venta Directa';
            break;
  
          case '2P':
            texto1 = 'Peso Variable';
            break;
  
          case '30':
            texto1 = 'Traslado';
            break;

          case '40':
            texto1 = 'Recuento (Tarea)';
            break;

          case '41':
            texto1 = 'Recuento Libre';
            break;
  
          case '60':
            texto1 = 'Reubicación Entradas';
            break;

          case '63':
            texto1 = 'Reubicación Pulmón-Picking';
            break;
  
          case '65':
            texto1 = 'Reubicación Manual';
            break;

          case '91':
            texto1 = 'Consulta de Stock';
            break;

          case '92':
            texto1 = 'Validación Carga';
            break;
      
          default:
            texto1 = '- ??? -';
            break;
        }

        //-- Inicializar Descripción de la Situación
        let texto2 = '';

        //-- Reemplazar SITUACIÓN por TEXTO DESCRIPTIVO
        switch (situacion) {
          case '':
            texto2 = '';
            break;

          case '1':
            texto2 = 'SIN CONFIRMAR';
            break;

          case '2':
            texto2 = 'C/Lote (S/Confirmar)';
            break;

          case '4':
            texto2 = 'Conf.MANUAL';
            break;
  
          case '5':
            texto2 = 'Conf.LÁSER';
            break;
    
          case '6':
            texto2 = 'Pdt.Reubicar'
            break;
      
          case '8':
            texto2 = 'CANCELADA';
            break;
        
          case '9':
            texto2 = 'CERRADA';
            break;
                      
          default:
            texto2 = '- ??? -';
            break;
        }

        //-- Texto Completo
        let texto = texto1 + ' ' + texto2;
        if (parentesis) { texto = '(' + texto + ')'; }

        //-- Retornar Resultado
        return texto;
      },

      //.......................................................................
      //-- Descripción de la Referencia Texto asociada a un movimiento DMA
      //.......................................................................
      f93DescripcionReferenciaTextoDMA(clave, referencia, parametroTexto, comentario) {

        //-- Inicializar Descripción 
        let texto = '';

        //-- Reemplazar CLAVE por TEXTO DESCRIPTIVO
        switch (clave) {
          case '10':
            texto = 'Nro. Pedido '+referencia;
            break;

          case '18':
            texto = 'Fecha '+this.f70AMD2fDMA(referencia.substr(0,8))+' Ruta '+referencia.substr(8,3);
            if (comentario != '') { texto += '<br/>'+comentario; }
            break;

          case '19':
            texto = 'Pedido '+referencia;
            break;

          case '20':
            texto = 'Fecha '+this.f70AMD2fDMA(referencia.substr(0,8))+' Ruta '+referencia.substr(8,3)+' Carga '+referencia.substr(11,1)+' Palé '+referencia.substr(12,2);
            if (comentario != '') { texto += '<br/>'+comentario; }
            break;

          case '21':
            texto = 'Fecha '+this.f70AMD2fDMA(referencia.substr(0,8))+' Ruta '+referencia.substr(8,3);
            if (comentario != '') { texto += '<br/>'+comentario; }
            break;
  
          case '2P':
            texto = 'Fecha '+this.f70AMD2fDMA(referencia.substr(0,8))+' Ruta '+referencia.substr(8,3);
            break;

          case '30':
            texto = 'Documento Tipo ('+referencia.substr(0,1)+') Nro.'+referencia.substr(1);
            break;

          case '40':
            texto = 'Tarea '+referencia;
            break;

          case '41':
            texto = referencia;
            break;
  
          case '60':
            texto = 'Tarea '+referencia;
            break;

          case '63':
            texto = 'Reubicación '+referencia;
            break;

          case '65':
            texto = 'Reubicación '+referencia;
            break;
    
          case '91':
            texto = 'Artículo '+referencia;
            break;

          case '92':
            texto = 'Fecha '+this.f70AMD2fDMA(referencia.substr(0,8))+' Ruta '+referencia.substr(8,3)+' Carga '+referencia.substr(11,1)+' Palé '+referencia.substr(12,2);
            if (comentario != '') { texto += '<br/>'+comentario; }
            switch (parametroTexto) {
              case '0':
                texto += '<br/><span style="color:green">OK</span>';
                break;
              case '1':
                texto += '<br/><span style="color:blue">Pendiente de Verificación Posterior</span>';
                break;
              case '2':
                texto += '<br/><span style="color:red">Error ARTÍCULO</span>';
                break;
              case '3':
                texto += '<br/><span style="color:red">Error CANTIDAD</span>';
                break;
              case '4':
                texto += '<br/><span style="color:red">Error UNIDADES</span>';
                break;
              case '5':
                texto += '<br/><span style="color:red">Error LOTE</span>';
                break;
              case '6':
                texto += '<br/><span style="color:red">Error CADUCIDAD</span>';
                break;
              default:
                break;
            }
            break;

          default:
            texto = '- ??? -';
            break;
        }

        //-- Retornar el Texto Calculado
        return texto ;
      },


      //------------------------------------------------------------------------------------------
      //--- Maquetar la Ubicación en formato (Humano)
      //---
      //--- Formatos :
      //---   0-Nativo .......: DDPPCCHHAAhh
      //---   1-Extendido (HTML) ......: "Demarcación DD Pasillo PP Calle CC Hueco HH Altura AA Fila hh"
      //---   5-Reducido (HTML) .......: "Pasillo PP Calle CC Hueco HH Altura AA"
      //---   9-Minimalista (HTML) ....: "PP CC HH AA"
      //---
      //---   91-Extendido (Texto) ....: "Demarcación DD Pasillo PP Calle CC Hueco HH Altura AA Fila hh"
      //---   95-Reducido (Texto) .....: "Pasillo PP Calle CC Hueco HH Altura AA"
      //---   99-Minimalista (Texto) ..: "PP CC HH AA"
      //------------------------------------------------------------------------------------------
      f93MaquetarUbicacion(ubicacion, formato) {

        //-- Inicializar la Salida
        let texto = '';

        //-- Complementar Ubicación (si fuese preciso)
        if (ubicacion.length < 13) {
          ubicacion = ubicacion + '0000000000000';
          ubicacion = ubicacion.substr(0,13);
        }

        //-- Es un Silo / Pila / ...
        let lbSilo = false;
        let ltDescripcionPasillo = 'Pasillo';

        if (ubicacion.substr(6,2) == '00') { 
          lbSilo = true; 
          ltDescripcionPasillo = 'Silo';
        }

        //console.log(' Ubicacion '+ubicacion+' lbSilo = '+lbSilo);

        //-- Componer resultado de acuerdo al formato solicitado
        switch (formato) {

          case 1: //-- Extendido
            //-- Descripción Básica
            texto = "<div class=\"igs-left\">Demarcación <big>"+ubicacion.substr(0,2)+
                     "</big> "+ltDescripcionPasillo+" <span class=\"igs-ubi-1\">"+ubicacion.substr(2,2)+
                     "</span> Calle <span class=\"igs-ubi-1\">"+ubicacion.substr(4,2)+"</span>";
    
            //-- Silos, Pilas cuentan con "Hueco 00, Altura 00, Fila 00"
            //-- En tales ubicaciones NO citamos estos 3 atributos
            if (!lbSilo) {
                texto += " Hueco <span class=\"igs-ubi-2\">"+ubicacion.substr(6,2)+
                        "</span> Altura <span class=\"igs-ubi-2\">"+ubicacion.substr(8,2);

                if (ubicacion.substr(10,2) != '00') {
                  texto += "</span> Fila <big>"+ubicacion.substr(10,2)+"</big>";
                }                    
            }

            //-- Cierre del DIV contenedor
            texto += "</div>";
            break;

          case 5:
            texto = "<div class=\"igs-left\">"+ltDescripcionPasillo+" <span class=\"igs-ubi-1\">"+ubicacion.substr(2,2)+
                                      "</span> Calle <span class=\"igs-ubi-1\">"+ubicacion.substr(4,2);
                                  
            //-- Silos, Pilas cuentan con "Hueco 00, Altura 00, Fila 00"
            //-- En tales ubicaciones NO citamos estos 3 atributos
            if (!lbSilo) {
              texto +=  "</span> Hueco <span class=\"igs-ubi-2\">"+ubicacion.substr(6,2)+
                        "</span> Altura <span class=\"igs-ubi-2\">"+ubicacion.substr(8,2)+
                        "</span>";
            }
            
            //-- Cierre del DIV contenedor
            texto += "</div>";
            break;

          case 9:
            texto =  "<div class=\"igs-left\"><span class=\"igs-ubi-1\">"+ubicacion.substr(2,2)+
                                    "</span>  <span class=\"igs-ubi-1\">"+ubicacion.substr(4,2)+
                                    "</span>  <span class=\"igs-ubi-2\">"+ubicacion.substr(6,2)+
                                    "</span>  <span class=\"igs-ubi-2\">"+ubicacion.substr(8,2)+
                                    "</span></div>";
            break;

          case 99:
              texto =  "  p"+ubicacion.substr(2,2)+
                       "  c"+ubicacion.substr(4,2)+
                       "  h"+ubicacion.substr(6,2)+
                       "  a"+ubicacion.substr(8,2);
              break;

            default:
              texto = ubicacion;
              break;                                                                                         
        }

        //-- Retornar el resultado calculado
        return texto;
      },


      //------------------------------------------------------------------------------------------
      //--- Maquetar el Dígito de Control de la Ubicación en formato (Humano)
      //---
      //--- Formatos :
      //---   0-Nativo .......: d
      //---   1-Extendido (HTML) ......: "(d)"
      //---   5-Reducido (HTML) .......: "d"
      //---   9-Minimalista (HTML) ....: "d"
      //------------------------------------------------------------------------------------------
      f93MaquetarDCUbicacion(ubicacion, formato) {

        //-- Inicializar la Salida
        let texto = '';

        //-- Complementar Ubicación (si fuese preciso)
        if (ubicacion.length < 13) {
          ubicacion = ubicacion + '0000000000000';
          ubicacion = ubicacion.substr(0,13);
        }


        //-- Componer resultado de acuerdo al formato solicitado
        switch (formato) {

          case 1: //-- Extendido
            texto = "<code>("+ubicacion.substr(12,1)+")</code>";
            break;

          case 5:
            texto = "<code>"+ubicacion.substr(12,1)+"</code>";
            break;

          case 9:
            texto = "<code>"+ubicacion.substr(12,1)+"</code>";
            break;

          default:
              texto = ubicacion.substr(12,1);
              break;                                                                                         
        }

        //-- Retornar el resultado calculado
        return texto;
      },



      //----------------------------------------------------------
      //--- Redondear al Múltiplo de 2 (más cercano)
      //----------------------------------------------------------
      f94Round2(numero) {
        return Math.ceil(numero / 2) * 2;
      },


      //----------------------------------------------------------
      //--- Componer la URL con la cual reclamar los Datos
      //----------------------------------------------------------
      f97GetURL(tabla, metodo) {
        //-- Retornar la URL a utilizar
        var URL = global.lnkServerAppURL + "s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&c="+this.$store.state.strCliente+"&t=" + tabla + "&m=" + metodo;

        //-- Informar Tabla y Método solicitado (y la URL completa)
        console.info("Reclamando Datos ('"+tabla+"','"+metodo+"') ==> "+URL);

        //-- Devolver el resultado obtenido
        return URL;
      },


      //---------------------------------------------------------------------------------
      //-- Reclamar Lista de Operarios (Almaceneros)
      //---------------------------------------------------------------------------------
      p98t200m691() {
        console.log('p98t200m691');

        //-- Enviar Solicitud al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=691";

        console.log('-- Lista de Operarios --');
        console.log(URL);

        axios.get(URL, {responseEncoding: 'utf-8'})
        .then(respuesta => {
              console.log('-- Datos Recibidos --');

              var datos = respuesta.data;

              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              if (flg == global.lnkGetDataOk) {

                //-- Actualizar Store
                this.$store.commit('p10StrListaOperarios', datos.t200m691);

              } else {
                if (datos.msg) { 

                  //-- Mostrar el Error al Usuario
                  this.p99AnuncioIA1('2','ERROR',this.f79DecodeHTML(datos.msg),'',null); 

                  //-- (E90) Sesion No Encontrada -> Pantalla de Login
                  if (datos.msg.search("(E90)") !== -1) { 
                    this.$store.commit('p10StrAbandonarSesion', true); 
                    this.$store.commit('p10StrUsuarioAutenticado', false); 
                  }

                } else {
                  this.p99AnuncioIA1('2','ERROR','(E200M691) Error Desconocido','',null); 
                }
              }

              this.$store.commit('p10StrRecibiendoDatos', false);

        })
        .catch(error => {
            console.log('** ERROR al Intentar Recibir Datos **');
            console.log(error.message);

            this.$store.commit('p10StrRecibiendoDatos', false);

            this.p99AnuncioIA1('2','ERROR',error.message,'',null); 
        });

      },

      //---------------------------------------------------------------------------------
      //-- Reclamar Lista de Artículos 
      //---------------------------------------------------------------------------------
      p98t200m692() {
        console.log('p98t200m692');

        //-- Enviar Solicitud al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=692";

        console.log('-- Lista de Artículos --');
        console.log(URL);

        axios.get(URL, {responseEncoding: 'utf-8'})
        .then(respuesta => {
              console.log('-- Datos Recibidos --');

              var datos = respuesta.data;

              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              if (flg == global.lnkGetDataOk) {

                //-- Actualizar Store
                this.$store.commit('p10StrListaArticulos', datos.t200m692);

              } else {
                if (datos.msg) { 

                  //-- Mostrar el Error al Usuario
                  this.p99AnuncioIA1('2','ERROR',this.f79DecodeHTML(datos.msg),'',null); 

                  //-- (E90) Sesion No Encontrada -> Pantalla de Login
                  if (datos.msg.search("(E90)") !== -1) { 
                    this.$store.commit('p10StrAbandonarSesion', true); 
                    this.$store.commit('p10StrUsuarioAutenticado', false); 
                  }

                } else {
                  this.p99AnuncioIA1('2','ERROR','(E200M692) Error Desconocido','',null); 
                }
              }

              this.$store.commit('p10StrRecibiendoDatos', false);

        })
        .catch(error => {
            console.log('** ERROR al Intentar Recibir Datos **');
            console.log(error.message);

            this.$store.commit('p10StrRecibiendoDatos', false);

            this.p99AnuncioIA1('2','ERROR',error.message,'',null); 
        });

      },



      //--------------------------------------------------------------------------------------
      //-- Lectura Datos Específicos del Distribuidor Actual
      //--------------------------------------------------------------------------------------
      p98LecturaListasCodigosDistribuidor() {
        console.log(' ** p98LecturaListasCodigosDistribuidor **');

        if ((this.$store.state.strDistribuidor == '') || (this.$store.state.strDistribuidor == '??????')) {
          this.$store.commit('p10StrListaOperarios', []);   //-- Almaceneros
          this.$store.commit('p10StrListaArticulos', []);   //-- Artículos
        } else {
          this.p98t200m691();                               //-- Almaceneros
          this.p98t200m692();                               //-- Artículos
        }
      },      

      //--------------------------------------------------------------------------------------
      //-- Procesar contenido JSON (transformando Funciones en modo Texto a Funciones Reales)
      //--------------------------------------------------------------------------------------
      f99JSONFuncional(texto) {

        var jsonTransformed = JSON.parse(texto, function (key, value) {

            //-- Crear FUNCION real, a partir del valor "texto"
            if (value && (typeof value === 'string') && value.indexOf("function") === 0) {
              var jsFunc = new Function('return ' + value)();
              return jsFunc;
            }
      
            //-- Resto de casos
            return value;
        });
          
        return jsonTransformed;

      },

      //--------------------------------------------------------------------------------------
      //-- Eliminar cualquier caracter especial de control
      //--------------------------------------------------------------------------------------
      f99TextoLimpio(texto) {
        //--------------------------------------------------------------------------------------
        // \r    = CR
        // \n    = LF
        // x0B   = VT
        // x0C   = FF
        // x5E   = ^
        // u0085 = LF
        // u2028 = Line      Separator
        // u2029 = Paragraph Separator
        //--------------------------------------------------------------------------------------

        // eslint-disable-next-line no-control-regex
        var textoLimpio = texto.replace(/[\r\n\x0B\x0C\x5E\u0085\u2028\u2029]+/g," ");
        return textoLimpio;
      },

      
      //----------------------------------------------------------
      //--- Mostrar mensaje en ventana reducida
      //--- y esperar hasta que se pulse (Ok)
      //----------------------------------------------------------
      /*
      p99MensajeError(mensaje) {
        
        //-- Eliminar cualquier caracter especial de control
        var mensajeLimpio = mensaje.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g," ");

        //-- Dejar evidencia del error
        console.error("### ERROR ### ("+ mensajeLimpio +")");

        //-- Mostrar la alerta al usuario
        alert({
            title: global.lnkAppName,
            message: mensaje,
            okButtonText: "OK"
          }).then(() => {
            console.info("***(e01)*** Mensaje de ERROR Leído por el Usuario : ("+ mensajeLimpio +")");
          });

      },
      */
 

      //----------------------------------------------------------
      //--- Mensajes Dinámicos
      //----------------------------------------------------------
      // eslint-disable-next-line no-unused-vars
      p99AnuncioIA1(tipoAnuncio, ubicacion, titulo, subtitulo, segundos) {
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        //==== Tipos de Anuncios ====       =================================== Ejemplos de Uso ===============================
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        // (1) - Éxito                      this.p99AnuncioIA1('1','1','¡ Éxito !', 'La tarea se ha realizado con éxito',0);
        // (2) - Error                      this.p99AnuncioIA1('2','1','ERROR', 'La tarea NO ha podido ser finalizada',3);
        // (3) - Aviso                      this.p99AnuncioIA1('3','1','Aviso', 'No se ha indicado la descripción de la tarea',5);
        // (4) - Sugerencia                 this.p99AnuncioIA1('4','1','Sugerencia', 'Indique 2 ó 3 valores',10);
        // (5) - Información                this.p99AnuncioIA1('5','1','Información', 'Si compra 2 cajas más, podrá recibir 1 Botella GRATIS',8);
        //---------------------------------------------------------------------------------------------------------------------------------------------------------

        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        //========== Tipos de Ubicación =============                            =================================== Ejemplos de Uso ===============================
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        // (1) - Izquierda - Arriba
        // (2) - Izquierda - Centro
        // (3) - Izquierda - Abajo
        //
        // (4) - Centro - Arriba
        // (5) - Centro - Centro
        // (6) - Centro - Abajo
        //
        // (7) - Derecha - Arriba
        // (8) - Derecha - Centro
        // (9) - Derecha - Abajo
        //
        // (Otro Valor) = VxD = (7)-Derecha - Arriba
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        
        //-- Determinar la Ubicación
        var wrkUbicacion = ubicacion;
        if ((wrkUbicacion < '1') || (wrkUbicacion > '9')) { wrkUbicacion = '7'; }

        //....

        var attUbicacion = null;

        switch (wrkUbicacion) {

          case '1':
            attUbicacion = SnotifyPosition.leftTop;
            break;

          case '2':
            attUbicacion = SnotifyPosition.leftCenter;
            break;

          case '3':
            attUbicacion = SnotifyPosition.leftBottom;
            break;

          case '4':
            attUbicacion = SnotifyPosition.centerTop;
            break;

          case '5':
            attUbicacion = SnotifyPosition.centerCenter;
            break;

          case '6':
            attUbicacion = SnotifyPosition.centerBottom;
            break;

          case '7':
            attUbicacion = SnotifyPosition.rightTop;
            break;

          case '8':
            attUbicacion = SnotifyPosition.rightCenter;
            break;

          case '9':
            attUbicacion = SnotifyPosition.rightBottom;
            break;

          default:
            attUbicacion = SnotifyPosition.rightTop;
            break;                                                                                         
        }

        //-- Determinar el Tipo de Anuncio
        var wrkTipoAnuncio = tipoAnuncio;
        if ((wrkTipoAnuncio < '1') || (wrkTipoAnuncio > '5')) { wrkTipoAnuncio = '1'; }

        //....

        var attTipoAnuncio = null;

        switch (wrkTipoAnuncio) {

          case '1':
            attTipoAnuncio = SnotifyStyle.success;
            break;

          case '2':
            attTipoAnuncio = SnotifyStyle.error;
            break;
  
          case '3':
            attTipoAnuncio = SnotifyStyle.warning;
            break;
    
          case '4':
            attTipoAnuncio = SnotifyStyle.simple;
            break;
      
          case '5':
            attTipoAnuncio = SnotifyStyle.info;
            break;

          default :
            attTipoAnuncio = SnotifyStyle.simple;
            break;
        }

        //----------------------------------------------------------------------
        //-- Revisar la Duración
        //----------------------------------------------------------------------
        //   -1            : Valor Temporal Predeterminado
        //    0             : Permanente (hasta que el usuario hace "click")
        //    1 ... 999999 : Temporal (auto-destruir tras N segundos)
        //----------------------------------------------------------------------
        if (segundos < 0)   { segundos = 20; }
        if (segundos > 60)  { segundos = 60; }

        //---------------------------------
        //-- Mostrar el Anuncio
        //---------------------------------
        /*
        this.$snotify.create({
          title: titulo,
          body: subtitulo,
          config: {
            position: attUbicacion,
            type: attTipoAnuncio,
            timeout: segundos * 1000,
            closeOnClick: true,
          }
        });
        */

        this.$snotify.html(`<div class="snotifyToast__title"><b>` + titulo + `</b></div>
                            <div class="snotifyToast__body">`+subtitulo+`</div> `,
                            {
                              position: attUbicacion,
                              type: attTipoAnuncio,
                              timeout: segundos * 1000,
                              showProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                             });

      },

      //----------------------------------------------------------
      //--- Mensajes Dinámicos
      //----------------------------------------------------------
      // eslint-disable-next-line no-unused-vars
      p99MensajeIH1(tipo, titulo, subtitulo, indicio, textoBoton) {

        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        //========== Tipos de Mensajes =============                            =================================== Ejemplos de Uso ===============================
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        // (1) - Éxito                                                          this.p99MensajeIH1('1','¡ Éxito !', 'La tarea se ha realizado con éxito','',null);
        //
        // (2) - Error                                                          this.p99MensajeIH1('2','ERROR', 'La tarea NO ha podido ser finalizada','',null);
        //
        // (3) - Aviso                                                          this.p99MensajeIH1('3','ATENCIÓN', 'No se ha indicado la descripción de la tarea','',null);
        //
        // (4) - Advertencia                                                    this.p99MensajeIH1('4','ADVERTENCIA', 'Tarea NO permitida','',null);
        //
        // (5) - Informativo                                                    this.p99MensajeIH1('5','Atención', 'Si compra 2 cajas más, podrá recibir 1 Botella GRATIS','',null);
        //
        // (6) - Pregunta (escoger entre varias opciones [botones])             this.p99MensajeIH1('6','Pregunta', '¿ Quieres agregar estos 10 artículos a la Cesta ?','No','Sí');
        //
        // (7) - Petición de Respuesta (texto)                                  this.p99MensajeIH1('7','Pregunta', 'Indique el Nombre para esta CESTA','Nombre de la Cesta','Confirmar');
        //
        // (8) -  ** Uso Futuro **
        //
        // (9) -  ** Uso Futuro **
        //
        //------------------------------------------

        //-- Eliminar cualquier caracter especial de control
        // eslint-disable-next-line no-control-regex
        var mensajeLimpio = subtitulo.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g," ");

        /*
        console.log(' *** Nuevo Mensaje : '+mensajeLimpio+' ***');        
        this.$swal(titulo, subtitulo, 'warning');
        console.log('-- Mensaje Mostrado --');
        */

        //-- Posible "traducción" (a humano) de los mensajes de error recibidos
        if (mensajeLimpio == "Request failed with status code null") {
          mensajeLimpio = "La conexión a Internet parece estar desactivada"
        }

        //::::::::::::::::::::::::
        //::: (1) - EXITO 
        //::::::::::::::::::::::::
        if (tipo == "1") {
            //-- Mostrar Mensaje
            this.$swal(titulo, mensajeLimpio, 'success').
            then((result) => {
               console.log('Mensaje de ÉXITO ['+titulo+'/'+mensajeLimpio+'] : Leído ('+result+')')
            }).catch((error) => {
               console.error(error)
            });
        }
        
        //::::::::::::::::::::::::
        //::: (2) - ERROR
        //::::::::::::::::::::::::
        if (tipo == "2") {
          //-- Mostrar Mensaje
          this.$swal(titulo, mensajeLimpio, 'error').
          then((result) => {
             console.log('Mensaje de ERROR ['+titulo+'/'+mensajeLimpio+'] : Leído ('+result+')')
          }).catch((error) => {
             console.error(error)
          });
        }

        //::::::::::::::::::::::::
        //::: (3) - AVISO
        //::::::::::::::::::::::::
        if (tipo == "3") {
          //-- Mostrar Mensaje
          this.$swal(titulo, mensajeLimpio, 'info').
          then((result) => {
             console.log('Mensaje de AVISO ['+titulo+'/'+mensajeLimpio+'] : Leído ('+result+')')
          }).catch((error) => {
             console.error(error)
          });
        }

        //::::::::::::::::::::::::
        //::: (4) - ADVERTENCIA
        //::::::::::::::::::::::::
        if (tipo == "4") {
          //-- Mostrar Mensaje
          this.$swal(titulo, mensajeLimpio, 'warning').
          then((result) => {
             console.log('Mensaje de ADVERTENCIA ['+titulo+'/'+mensajeLimpio+'] : Leído ('+result+')')
          }).catch((error) => {
             console.error(error)
          });
        }

        //::::::::::::::::::::::::
        //::: (5) - INFORMACIÓN
        //::::::::::::::::::::::::
        if (tipo == "5") {
          //-- Mostrar Mensaje
          this.$swal(titulo, mensajeLimpio, 'info').
          then((result) => {
             console.log('Mensaje INFORMATIVO ['+titulo+'/'+mensajeLimpio+'] : Leído ('+result+')')
          }).catch((error) => {
             console.error(error)
          });
        }

        //::::::::::::::::::::::::
        //::: (6) - PREGUNTA
        //::::::::::::::::::::::::
        if (tipo == "6") {

          this.$swal({
            title : titulo, 
            text : mensajeLimpio, 
            type : 'question',
            showCancelButton : true,
            cancelButtonText: indicio,
            confirmButtonText : textoBoton,
            showCloseButton: true,
            showLoaderOnConfirm: true 
          }).          
          then((result) => {
            //console.log('Resultado : '+result);
            
            if (result.value) {
              console.log('!!! Éxito !!!');
              return new Promise(resolve => { resolve(textoBoton); });
            } else {
              console.log(' *** Acción NO CONFIRMADA *** ');
              return new Promise(resolve => { resolve(indicio); });
            }            
          });
          
        }

        //::::::::::::::::::::::::::::::::::::
        //::: (7) - PETICIÓN DE RESPUESTA
        //::::::::::::::::::::::::::::::::::::
        if (tipo == "7") {
          //-- Mostrar Mensaje
          /*
          this.$swal({
            title: titulo,
            html:
              ' <input ' + indicio + '</em>' +
              ' Titulo <a href="#">'+ titulo +'</a> ' +
              ' mensaje <b>'+ mensajeLimpio +'</b>' +
              '<strong>Cualquier otro contenido</strong>',
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
          });
          */

          this.$swal({
            title: titulo,
            text: mensajeLimpio,
            input: 'text',
            inputPlaceholder: indicio,
            showCloseButton: false,
            showCancelButton : true,
            cancelButtonText: "Cancelar"
          }).
          then((result) => {
            //console.log('Resultado :');
            //console.dir(result);
            
            if (result.value) {
              console.log('!!! Éxito !!!');
              return new Promise(resolve => { resolve(result.value); });
            } else {
              console.log(' *** Acción NO CONFIRMADA *** ');
              return new Promise(resolve => { resolve(''); });
            }            
          });

        }

        //-- Llamada a función (then))
        return new Promise(resolve => {
          resolve('???');
        });
        

      },

      //----------------------------------------------------------------
      //--- Obtener Ruta Absoluta de la Imagen a mostrar (formato PNG)
      //----------------------------------------------------------------
      f99ImageName(imagen) {
        return global.lnkServerImgURL + imagen + ".png";
      }, 

      
      //----------------------------------------------------------------
      //--- Obtener Ruta Absoluta de la Imagen a mostrar (formato GIF)
      //----------------------------------------------------------------
      f99ImageNameGIF(imagen) {
        return global.lnkServerImgURL + imagen + ".gif";
      }, 

      
      //----------------------------------------------------------
      //--- Obtener Nombre Imagen (segun Tipo de Documento)
      //----------------------------------------------------------
      f99IconoDocumento(tipoDocumento, color) {
        //console.log('f99IconoDocumento ['+tipoDocumento+']');
        return "ih1-documento-" + tipoDocumento + "-" + color;
      },
      
      //----------------------------------------------------------------------
      //--- Mostrar Mensaje en Consola (LOG)
      //----------------------------------------------------------------------
      //---  (nivel) : 1-Error // 2-Info // 3-Debug // 4-Temp // 5-Trace
      //---  (modo)  : 1-Log   // 2-Dir  
      //---  (valor) : Texto u Objeto a informar
      //----------------------------------------------------------------------
      p99Log(nivel,modo,valor) {

        //-- Mostrar SOLO si el NIVEL del MENSAJE es compatible
        if (nivel <= global.lnkDebugLevel) {

          //-- LOG
          if (modo == 1) { 
            console.log('-'+this.$store.state.strModuleName+'- '+valor); 
          }

          //-- DIR
          if (modo == 2) { 
            console.log('********* '+this.$store.state.strModuleName+' ********');
            console.dir(valor); 
            console.log('*****************************');
          }

        }
      }



/*
//==========================================================================================================================
//----------   Código VERIFICADO, pendiente de aplicar (cuando resulte necesario)
//==========================================================================================================================

    //------------------------------------------------------------------------------------------
    //--  Mensajes (en modo Post-it)
    //------------------------------------------------------------------------------------------
    lp90PruebaAnuncios01() {
        console.log('*** lp90PruebaAnuncios01 ***');
        //this.$snotify.success('Example body content');
        
        this.$snotify.success('La tarea ha finalizado con ÉXITO', 'Enhorabuena', {timeout: 0});
        this.$snotify.error('No hay contacto con el servidor. ¿ Tienes acceso a Internet ?', 'Error');
        this.$snotify.warning('Sólo quedan 10 unidades', 'Aviso', {timeout: 0});
        this.$snotify.info('Último acceso realizado hace 43 días', 'Información', {timeout: 0});
        this.$snotify.simple('Indica tu dirección de correo', 'Sugerencia', {timeout: 0});
    },

    //------------------------------------------------------------------------------------------
    //--  Mensajes (en modo Post-it)
    //------------------------------------------------------------------------------------------
    lp90PruebaAnuncios02() {

        this.$snotify.prompt('¿ Cuántos dedos tienes en la mano ?', 'Pregunta', {
          timeout: 0,
          placeholder: 'Respuesta'
        });

        // eslint-disable-next-line no-unused-vars
        this.$snotify.async('Descargando contenidos ....', 'Sincronizando', () => new Promise((resolve, reject) => {
            setTimeout(() => resolve({
            title: '¡ Éxito !',
            body: 'Los datos necesarios han sido actualizados correctamente',
            config: {
                closeOnClick: true
            }
            }), 6000);
        }));

        this.$snotify.confirm('¿ Estás seguro ?', 'Atención', {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
            //Yes
            {text: 'Sí', action: () => console.log('Respuesta : SÍ'), bold: false},
            //No
            {text: 'No', action: () => console.log('Respuesta : NO')},
            //Later
            {text: 'Más tarde', action: (toast) => {console.log('Respuesta : MÁS TARDE'); this.$snotify.remove(toast.id); } },
            //Close
            {text: 'Cerrar', action: (toast) => {console.log('Respuesta : CERRAR'); this.$snotify.remove(toast.id); }, bold: true},
            ]
        });

      },

      //------------------------------------------------------------------------------------------
      //--  Mensajes (en modo Post-it)
      //------------------------------------------------------------------------------------------
      lp90PruebaAnuncios03() {

          this.$snotify.html(`<div class="snotifyToast__title"><b>Título Mensaje HTML</b></div>
                              <div class="snotifyToast__body"><i>Mensaje</i> <b>HTML</b> <u>contenido dinámico</u></div> `, {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
          });

          //---------

          const yesAction = (toast) => {
            if (!toast.value.match('1234')) {
              console.log('Respuesta incorrecta');
              toast.valid = false;
              return false;
            } else {
              console.log('Respuesta CORRECTA');
              toast.valid = true; // default value
              this.$snotify.remove(toast.id)
            }
          }

          const noAction = (toast) => {
            console.log('Respuesta : NO');
            this.$snotify.remove(toast.id) // default
          }

          this.$snotify.prompt('¿ Tienes más de 18 años ?', 'Pregunta', {
            buttons: [
              {text: 'Sí', action: yesAction, bold: true },
              {text: 'No', action: noAction },
            ],
            placeholder: 'Dinos tu respuesta'
          });

          //---------------------------

          this.$snotify.simple('Mensaje con Icono Asociado', 'Ejemplo', {
            timeout: 8000,
            showProgressBar: false,
            closeOnClick: true,
            icon: global.lnkServerImgURL + 'ih1-stock-azul.png'
          });
      },

    //------------------------------------------------------------------------------------------
    //--  Mensajes (en modo Ventana)
    //------------------------------------------------------------------------------------------
    lp90PruebaMensajes(tipo) {
        console.log('*** lp90PruebaMensajes ***');

        if (tipo == '1') {
            this.p99MensajeIH1('1','¡ Éxito !', 'La tarea se ha realizado con éxito','',null);
        }

        if (tipo == '2') {
            this.p99MensajeIH1('2','ERROR', 'La tarea NO ha podido ser finalizada','',null);
        }

        if (tipo == '3') {
            this.p99MensajeIH1('3','ATENCIÓN', 'No se ha indicado la descripción de la tarea','',null);
        }

        if (tipo == '4') {
            this.p99MensajeIH1('4','ADVERTENCIA', 'Tarea NO permitida','',null);
        }
        
        if (tipo == '5') {
            this.p99MensajeIH1('5','Atención', 'Si compra 2 cajas más, podrá recibir 1 Botella GRATIS','',null);
        }
         
        if (tipo == '6') {
            this.p99MensajeIH1('6','Pregunta', '¿ Quieres agregar estos 10 artículos a la Cesta ?','No','Sí');
        }

        if (tipo == '7') {
            this.p99MensajeIH1('7','Pregunta', 'Indique el Nombre para esta CESTA','Nombre de la Cesta','Confirmar');
        }

    },


      //------------------------------------------------------------------------------------------
      //--  Mensajes (en modo Post-it)
      //------------------------------------------------------------------------------------------
      lp90PruebaAnuncios04() {
        this.p99AnuncioIA1('1','1','Éxito','La tarea ha finalizado correctamente.',10)
        this.p99AnuncioIA1('2','3','Error','Error <b>E27</b><br/>No se ha indicado el <i>USUARIO</i>',30)
        this.p99AnuncioIA1('3','5','Aviso','La tarea ha finalizado correctamente.',20)
        this.p99AnuncioIA1('4','9','Advertencia','La tarea ha finalizado correctamente.',15)
        this.p99AnuncioIA1('5','7','Información','La tarea ha finalizado correctamente.',40)
      }


*/
      
    }
  }
  